import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8e64b53c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "aggregate-daily-sales-report"
};
var _hoisted_2 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_type_tab = _resolveComponent("c-type-tab");
  var _component_c_date_header_with_btn = _resolveComponent("c-date-header-with-btn");
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_table = _resolveComponent("el-table");
  var _component_Overlay = _resolveComponent("Overlay");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_type_tab, {
    onConfirmType: _ctx.confirmType
  }, null, 8, ["onConfirmType"]), _createVNode(_component_c_date_header_with_btn, {
    ref: "dateRef",
    type: _ctx.params.type,
    showBtn: false,
    onConfirmDate: _ctx.confrimDate,
    onSwitch: _ctx.switchClick
  }, null, 8, ["type", "onConfirmDate", "onSwitch"]), _createVNode(_component_el_table, {
    data: _ctx.aggregateSaleCountData,
    "span-method": _ctx.objectSpanMethod,
    "row-class-name": _ctx.tableRowClassName,
    border: true
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        prop: "customer",
        label: "客户名称"
      }), _createVNode(_component_el_table_column, {
        prop: "stone",
        label: "物料名称"
      }), _createVNode(_component_el_table_column, {
        prop: "net_weight",
        label: "净重(吨)"
      }), _createVNode(_component_el_table_column, {
        prop: "customer_pay",
        label: "金额(元)"
      }), _createVNode(_component_el_table_column, {
        prop: "car_cnt",
        label: "车次",
        width: "60"
      })];
    }),
    _: 1
  }, 8, ["data", "span-method", "row-class-name"])]), _createVNode(_component_Overlay, {
    showOverlay: _ctx.showOverlay
  }, null, 8, ["showOverlay"])]);
}